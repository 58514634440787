import React, { useContext, useEffect } from 'react';
import { ProjectSetting } from '../types';
import { Button, Form } from 'semantic-ui-react';
import { TextInputField } from '../../../components/forms/fields';
import { useForm } from 'react-hook-form';
import Confirm from '../../../components/dialogs/Confirm';
import { v4 as uuidv4 } from 'uuid';
import { ThemeContext, themes } from '../../../contexts/theme/ThemeContext';

type Props = {
  settingKey: string;
  settings: ProjectSetting[];
  loading: boolean;
  updateOrCreateSetting: (data: Omit<ProjectSetting, 'id'>) => Promise<void>;
  reloadSettings: () => Promise<void>;
};

const ProjectItemsSettingsPanel = (props: Props) => {
  const {
    settingKey,
    settings,
    loading,
    updateOrCreateSetting,
    reloadSettings,
  } = props;

  const formMethods = useForm({
    defaultValues: {
      [`${settingKey}_add_new`]: '',
      [`${settingKey}_add_new_bg`]: 'Компютърна услуга',
      itemTypes: [],
    },
  });

  const itemTypes = settings.find(({ key }) => key === settingKey);

  const { setValue } = formMethods;

  useEffect(() => {
    setValue(
      'itemTypes',
      settings.find(({ key }) => key === settingKey)?.value || []
    );
  }, [settings, setValue, settingKey]);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Form loading={loading} inverted={theme === themes.dark}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <TextInputField
            label="Add a new item (EN)"
            name={`${settingKey}_add_new`}
            submitHandler={() => {}}
            className="project-settings-field"
            {...formMethods}
          />{' '}
          <TextInputField
            label="Translation (BG)"
            name={`${settingKey}_add_new_bg`}
            submitHandler={() => {}}
            className="project-settings-field"
            {...formMethods}
          />{' '}
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              inverted={theme === themes.dark}
              icon="plus"
              basic
              style={{
                verticalAlign: 'text-top',
                fontSize: '1.1em',
              }}
              onClick={async () => {
                const value = formMethods.getValues(`${settingKey}_add_new`);
                const bgTranslation = formMethods.getValues(
                  `${settingKey}_add_new_bg`
                );
                if (value) {
                  let updatedValue;

                  if (itemTypes) {
                    updatedValue = itemTypes.value.find(
                      (element: { en: string; bg: string }) =>
                        element.en === value
                    )
                      ? itemTypes.value
                      : itemTypes.value.concat([
                          { en: value, bg: bgTranslation || '', id: uuidv4() },
                        ]);
                  } else {
                    updatedValue = [
                      {
                        en: value,
                        bg: bgTranslation || '',
                        id: uuidv4(),
                      },
                    ];
                  }
                  await updateOrCreateSetting({
                    key: settingKey,
                    value: JSON.stringify(updatedValue),
                    module: 'projects',
                  });
                  formMethods.setValue(`${settingKey}_add_new`, '');
                  reloadSettings();
                }
              }}
            />
          </div>
        </div>
        {itemTypes?.value.map(
          (imageType: { en: string; bg: string }, idx: number) => (
            <React.Fragment key={idx}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <TextInputField
                  label=""
                  name={`itemTypes[${idx}].en`}
                  submitHandler={async () => {
                    const value = formMethods.getValues().itemTypes;
                    await updateOrCreateSetting({
                      key: settingKey,
                      value: JSON.stringify(value),
                      module: 'projects',
                    });
                  }}
                  activateInputOnClick
                  className="project-settings-field"
                  {...formMethods}
                />{' '}
                <TextInputField
                  label=""
                  name={`itemTypes[${idx}].bg`}
                  submitHandler={async () => {
                    const value = formMethods.getValues().itemTypes;
                    await updateOrCreateSetting({
                      key: settingKey,
                      value: JSON.stringify(value),
                      module: 'projects',
                    });
                  }}
                  activateInputOnClick
                  placeholder={`${imageType.en} (BG)`}
                  className="project-settings-field"
                  {...formMethods}
                />{' '}
                <Confirm
                  content="Are you sure you want to delete the item?"
                  trigger={
                    <Button
                      inverted={theme === themes.dark}
                      icon="trash"
                      basic
                      size="large"
                      style={{
                        alignSelf: 'flex-end',
                        verticalAlign: 'bottom',
                      }}
                    />
                  }
                  onSubmit={async () => {
                    const updatedValue = itemTypes.value
                      .slice(0, idx)
                      .concat(itemTypes.value.slice(idx + 1));
                    await updateOrCreateSetting({
                      key: settingKey,
                      value: JSON.stringify(updatedValue),
                      module: 'projects',
                    });
                    reloadSettings();
                  }}
                />
              </div>
            </React.Fragment>
          )
        )}
      </Form>
    </>
  );
};

export default ProjectItemsSettingsPanel;
