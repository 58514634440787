import React from 'react';
import { TeamMember } from '../../team/types';
import { TeamMemberMonthlySalary } from '../types';
import styled from 'styled-components';
import SalaryUnitUpdateField from './SalaryUnitUpdateField';
import useAPIRequest from '../../../hooks/useAPIRequest';
import MonthlySalariesResource from '../../../api/resources/salaries/MonthlySalariesResource';
import { Id } from '../../../types/base';
import TeamMemberResource from '../../../api/resources/team/TeamMemberResource';
import Decimal from 'decimal.js-light';
import NotesField from './NotesField';
import TeamMemberSalaryNotesResource from '../../../api/resources/salaries/TeamMemberSalaryNotesResource';

const TeamMemberSalaryLabel = styled.div`
  height: 75px;
  width: 280px;
  color: #fff;
  background-color: #00a650 !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-family: MarkOT-Medium, sans-serif;
  font-size: 1.1rem;
  position: relative;
`;

const InnerContainer = styled.div`
  border-radius: 20px !important;
  background-color: #0084ff;
  height: 100%;
  clip-path: polygon(0 0, 0 115%, 100% -25%, 100% 0);
  font-family: MarkOT-Medium, sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
`;

const SalarySum = styled.div`
  text-align: right;
  position: absolute;
  top: 45px;
  width: 280px;
  font-size: 1.8rem;
  padding-right: 5px;
  font-weight: bold;
`;

const SalaryComponent = styled.div`
  font-family: MarkOT-Medium, sans-serif;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  width: 280px;
`;

const formatterFunction = new Intl.NumberFormat('bg', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format;

const TeamMemberSalaryTable = ({
  teamMember,
  teamMemberSalary,
  locked,
  monthlySalariesId,
  refetchSalaries,
  onTeamMemberClick,
  refetchTeamMembers,
  teamMemberNote,
  skipPerformanceComponent,
  showPreviewValues,
}: {
  locked: boolean;
  monthlySalariesId: Id;
  refetchSalaries: () => void;
  teamMember: TeamMember;
  teamMemberSalary: TeamMemberMonthlySalary;
  onTeamMemberClick: () => void;
  refetchTeamMembers: () => void;
  teamMemberNote: string | undefined;
  skipPerformanceComponent: boolean;
  showPreviewValues: boolean;
}) => {
  const { performRequest: updateBonusField } = useAPIRequest(
    MonthlySalariesResource.updateBonusField
  );

  const { performRequest: updateNotes } = useAPIRequest(
    TeamMemberSalaryNotesResource.createOrUpdateGeneralSalaryNote
  );

  const { performRequest: updateTeamMember } = useAPIRequest(
    TeamMemberResource.updateById
  );

  const salaryComponents = [
    {
      label: showPreviewValues ? 'legacy base' : 'base',
      value: locked ? (
        formatterFunction(
          Number(
            showPreviewValues
              ? teamMemberSalary.previewBase
              : teamMemberSalary.base
          )
        )
      ) : (
        <SalaryUnitUpdateField
          initialValue={
            showPreviewValues
              ? teamMemberSalary.previewBase
              : teamMemberSalary.base
          }
          onSubmit={(value) => {
            const valuesToUpdate = showPreviewValues
              ? { previewBaseSalary: value }
              : { baseSalary: value };
            updateTeamMember(
              teamMemberSalary.teamMemberId,
              valuesToUpdate
            ).then(() => {
              refetchSalaries();
              refetchTeamMembers();
            });
          }}
        />
      ),
    },
    ...(Number(teamMemberSalary.unpaidLeaveDeduction)
      ? [
          {
            label: 'unpaid leave',
            value: `-${formatterFunction(
              Number(teamMemberSalary.unpaidLeaveDeduction)
            )}`,
            color: '#fac710',
          },
        ]
      : []),
    ...(!skipPerformanceComponent
      ? [
          {
            label: 'perf. component',
            value: formatterFunction(
              Number(teamMemberSalary.performanceComponent)
            ),
          },
        ]
      : []),
    {
      label: 'overtime',
      value: formatterFunction(Number(teamMemberSalary.overtime)),
    },
    {
      label: 'bonus',
      value:
        locked || showPreviewValues ? (
          formatterFunction(Number(teamMemberSalary.bonus ?? 0))
        ) : (
          <SalaryUnitUpdateField
            initialValue={teamMemberSalary.bonus}
            onSubmit={(value) => {
              updateBonusField(
                monthlySalariesId,
                teamMemberSalary.teamMemberId,
                value ?? 0
              ).then(() => {
                refetchSalaries();
              });
            }}
          />
        ),
    },
    {
      label: 'bank card',
      value:
        locked || showPreviewValues ? (
          formatterFunction(Number(teamMemberSalary.bankCardPayment))
        ) : (
          <SalaryUnitUpdateField
            initialValue={teamMemberSalary.bankCardPayment}
            onSubmit={(value) => {
              updateTeamMember(teamMemberSalary.teamMemberId, {
                salaryBankCard: value,
              }).then(() => {
                refetchSalaries();
                refetchTeamMembers();
              });
            }}
            color={
              Number(teamMemberSalary.unpaidLeaveDeduction) ? '#fac710' : '#fff'
            }
          />
        ),
      color: Number(teamMemberSalary.unpaidLeaveDeduction) ? '#fac710' : '#fff',
    },
    {
      label: 'total cash',
      value: new Decimal(
        (showPreviewValues
          ? teamMemberSalary.previewPerformanceComponentTotal
          : teamMemberSalary.total) ?? 0
      )
        .sub(new Decimal(teamMemberSalary.bankCardPayment ?? 0))
        .isPositive()
        ? formatterFunction(
            new Decimal(
              (showPreviewValues
                ? teamMemberSalary.previewPerformanceComponentTotal
                : teamMemberSalary.total) ?? 0
            )
              .sub(new Decimal(teamMemberSalary.bankCardPayment ?? 0))
              .toNumber()
          )
        : formatterFunction(0),
    },
    {
      label: 'prod. rate',
      value: teamMemberSalary.productivityRate,
    },
    ...(!showPreviewValues
      ? [
          {
            label: 'monthly',
            value: teamMemberSalary.monthlyAverage
              ? formatterFunction(Number(teamMemberSalary.monthlyAverage))
              : '-',
          },
          {
            label: 'annual',
            value: teamMemberSalary.yearlyAverage
              ? formatterFunction(Number(teamMemberSalary.yearlyAverage))
              : '-',
          },
          {
            label: 'growth',
            value: teamMemberSalary.sixMonthsSalaryGrowth
              ? formatterFunction(
                  Number(teamMemberSalary.sixMonthsSalaryGrowth)
                )
              : '-',
          },
        ]
      : []),
    {
      label: 'note',
      value: showPreviewValues ? (
        teamMemberNote
      ) : (
        <NotesField
          initialValue={teamMemberNote || ''}
          onSubmit={(value) => {
            updateNotes({
              teamMemberId: teamMemberSalary.teamMemberId,
              note: value,
            });
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TeamMemberSalaryLabel>
        <InnerContainer onClick={onTeamMemberClick}>
          <div>{teamMember.name.split(' ')[0]}</div>
        </InnerContainer>
        <SalarySum>
          {formatterFunction(
            Number(
              showPreviewValues
                ? teamMemberSalary.previewPerformanceComponentTotal
                : teamMemberSalary.total || 0
            )
          )}
        </SalarySum>
      </TeamMemberSalaryLabel>
      <div style={{ padding: '5px' }}>
        {salaryComponents.map((component) => {
          return (
            <SalaryComponent
              key={component.label}
              style={{ color: component.color || '#fff' }}
            >
              <div>{component.label}</div>
              <div style={{ textAlign: 'right', maxWidth: '70%' }}>
                {component.value}
              </div>
            </SalaryComponent>
          );
        })}
      </div>
    </div>
  );
};

export default TeamMemberSalaryTable;
