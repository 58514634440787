import React from 'react';
import { Form, Input } from 'semantic-ui-react';

import {
  TEAM_MEMBER_EMPLOYMENT_TYPE_OPTIONS,
  TEAM_MEMBER_ROLE_OPTIONS,
} from '../constants';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import {
  CheckboxField,
  PasswordInputField,
  SelectInputField,
  NumberInputField,
  DateInputField,
  EmailInputField,
  TextInputField,
} from '../../../components/forms/fields';
import LeaveAllowanceFields from './LeaveAllowanceFields';
import FieldsDivider from '../../../components/forms/FieldsDivider';
import { nameBGValidationpattern } from '../../../components/validationRules';
import { UserContext } from '../../auth/UserContext';
import { hasPermission } from '../../auth/utils';
import { SALARIES_PERMISSION } from '../permission';
import { StyledFormField } from '../../../components/forms/fields/Fields.styled';
import { formatBGNSummary } from '../../userProfile/financial-stats/YearSalaryChart';
import { roundToTwo } from '../../../utils/numberUtils';

type Props = FormFieldsComponentTypeProps;

const EditFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  const { user } = UserContext.useContainer();

  return (
    <>
      <Form.Group widths="equal">
        <TextInputField
          rules={{ required: { value: true, message: 'Please enter a name.' } }}
          label="Name"
          name="name"
          placeholder="First Name Surname"
          {...formMethods}
        />
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter a valid name in Bulgarian.',
            },
            ...nameBGValidationpattern,
          }}
          label="Name (BG)"
          name="nameBG"
          placeholder="Собствено Фамилно"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter the initials.',
            },
          }}
          label="Initials"
          name="initials"
          placeholder="Initials"
          {...formMethods}
        />
        <TextInputField
          rules={{
            required: {
              value: true,
              message: 'Please enter a telephone number.',
            },
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
              message: 'Please enter a valid phone number',
            },
          }}
          label="Telephone"
          name="telephone"
          placeholder="Mobile"
          {...formMethods}
        />
      </Form.Group>
      <FieldsDivider horizontal content="Account" />
      <Form.Group widths="equal">
        <EmailInputField
          rules={{
            required: { value: true, message: 'Please enter an email' },
          }}
          placeholder="Email"
          name="email"
          label="Email"
          {...formMethods}
        />
        <PasswordInputField
          name="password"
          label="Password"
          rules={{}}
          placeholder="(leave blank if unchanged)"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <CheckboxField label="Full Access" name="fullAccess" {...formMethods} />
      </Form.Group>
      <FieldsDivider horizontal content="Employment" />
      <Form.Group widths="equal">
        <SelectInputField
          label="Employment Type"
          options={TEAM_MEMBER_EMPLOYMENT_TYPE_OPTIONS}
          name="employmentType"
          rules={{ required: true }}
          {...formMethods}
        />
        <SelectInputField
          label="Role"
          options={TEAM_MEMBER_ROLE_OPTIONS}
          name="role"
          rules={{
            required: { value: true, message: 'Please select a role.' },
          }}
          {...formMethods}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <DateInputField
          label="Start Date"
          name="startDate"
          rules={{ required: { value: true, message: 'Please enter a date.' } }}
          dateOnly
          {...formMethods}
        />
        <DateInputField
          label="End Date"
          dateOnly
          name="endDate"
          {...formMethods}
        />
      </Form.Group>
      <FieldsDivider horizontal content="Leave Allowance" />
      <LeaveAllowanceFields {...formMethods} instance={instance} />

      {hasPermission(user, SALARIES_PERMISSION) && (
        <>
          <FieldsDivider horizontal content="Salaries" />
          <Form.Group widths="equal">
            <StyledFormField inline>
              <label>Cost:</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Input disabled value={instance.cost} />
              </div>
            </StyledFormField>
            <NumberInputField
              label="Productivity"
              name="productivity"
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the productivity rate.',
                },
              }}
              {...formMethods}
            />
            <NumberInputField
              label="Legacy Base Salary"
              name="previewBaseSalary"
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the legacy base salary.',
                },
              }}
              {...formMethods}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <NumberInputField
              label="Base Salary"
              name="baseSalary"
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the base salary.',
                },
              }}
              {...formMethods}
            />
            <NumberInputField
              label="Bank Card Salary"
              name="salaryBankCard"
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the bank card salary.',
                },
              }}
              {...formMethods}
            />
            <NumberInputField
              label="Bonus Threshold"
              name="minimalBonus"
              rules={{
                required: {
                  value: true,
                  message: 'Please enter the base salary.',
                },
              }}
              {...formMethods}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <StyledFormField inline>
              <label>Monthly Wage:</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Input
                  disabled
                  value={formatBGNSummary(instance?.averageSalary ?? 0)}
                />
              </div>
            </StyledFormField>
            <StyledFormField inline>
              <label>Growth:</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Input
                  disabled
                  value={formatBGNSummary(instance?.sixMonthsSalaryGrowth ?? 0)}
                />
              </div>
            </StyledFormField>
            <StyledFormField inline>
              <label>Images per Month:</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Input
                  disabled
                  value={`${roundToTwo(instance?.averageImgCount ?? 0)} CGI`}
                />
              </div>
            </StyledFormField>
          </Form.Group>
        </>
      )}
    </>
  );
};

export default EditFormFields;
