import React, { useEffect, useMemo, useState } from 'react';
import NewEventFormFields from './forms/NewEventFormFields';
import EventFormFields from './forms/EventFormFields';
import ProjectResource from '../../api/resources/projects/ProjectResource';
import ProjectScheduleEventResource from '../../api/resources/projectSchedule/ProjectScheduleEventResource';
import { Project } from '../projects/types';
import ProjectScheduleMilestoneEventResource from '../../api/resources/projectSchedule/ProjectScheduleMilestoneEventResource';
import MilestoneFormFields from '../resourceSchedule/forms/MilestoneFormFields';
import Calendar from '../../components/calendar/Calendar';
import { Id } from '../../types/base';
import { projectEditInFlowConfig } from '../projects/Table';
import { getProjectFullName } from '../projects/utils';
import { flowProjectFilters } from '../projects/Filters';
import useAPIRequest from '../../hooks/useAPIRequest';
import ResourceScheduleProjectEventResource from '../../api/resources/resourceSchedule/ResourceScheduleProjectEventResource';
import TeamMemberResource from '../../api/resources/team/TeamMemberResource';
import { TeamMember } from '../team/types';
import { ResourceScheduleEvent } from '../resourceSchedule/ResourceSchedule';
import { LocalStorage } from '../../api/BrowserStorage';
import {
  FULL_ACCESS_PERMISSION,
  PROJECT_SCHEDULE_PERMISSION,
} from '../team/permission';
import { UserContext } from '../auth/UserContext';
import { hasPermission } from '../auth/utils';
import EventInfoBox from './EventInfoBox';
import { TimeFormatter } from '../../utils/dateUtils';

export type ProjectScheduleEvent = {
  id: Id;
  start: string;
  end: string;
  order: number;
  project: Project;
  color: string;
  name: string;
  nameTemplate: string;
  roles: string[];
  teamMemberRoles: { [x: string]: string[] };
};

export type Milestone = {
  id: Id;
  datetime: string;
  endDatetime: string;
  color: string;
  name: string;
};

const ProjectSchedule = () => {
  const {
    data: leaveResponse,
    performRequest: getLeave,
    error,
  } = useAPIRequest(ProjectResource.list);

  const { user } = UserContext.useContainer();

  const [leaveIds, setLeaveIds] = useState(
    LocalStorage.loadState('holidayProjectIds')
  );

  useEffect(() => {
    const performRequest = async () => {
      if (!leaveIds && !leaveResponse && !error) {
        const res = await getLeave({
          filter: { where: { isHoliday: true }, fields: { id: true } },
        });
        LocalStorage.saveState('holidayProjectIds', res.data);
        setLeaveIds(res.data);
      }
    };
    performRequest();
  }, [getLeave, leaveIds, error, leaveResponse]);

  const eventAdditionalFilters = useMemo(() => {
    return {
      or: leaveIds.map((p: Pick<Project, 'id'>) => ({
        projectId: p.id,
      })),
    };
  }, [leaveIds]);

  return (
    <>
      <Calendar
        showOccupationRate
        topCalendar={{
          calendarId: 'project-schedule-calendar',
          defaultCreateItemDataProps: { nameTemplate: '{#items} - {lead}' },
          editEventLabel: 'Edit stage',
          editMilestoneFormFields: MilestoneFormFields,
          milestoneAPIResource: ProjectScheduleMilestoneEventResource,
          milestoneNameAccessor: (milestone: Milestone) => {
            return `${milestone.name}${
              milestone.datetime &&
              `- ${TimeFormatter.format(new Date(milestone.datetime))}`
            }`;
          },
          milestoneResourceIdAccessor: 'projectId',
          resourceNameAccessor: (project: Project) =>
            getProjectFullName(project),
          resourceStartAccessor: 'startDate',
          resourceEndAccessor: 'deadline',
          resourceColorAccessor: 'eventColor',
          resourceAdditionalFilters: {
            ...flowProjectFilters,
          },
          editEventFormFields: EventFormFields,
          eventIncludeRelations: [{ relation: 'project' }],
          eventAPIResource: ProjectScheduleEventResource,
          resourceAPIResource: ProjectResource,
          resourceOrderBy: ['onlyFlow DESC', 'ref ASC'],
          createEventFormFields: NewEventFormFields,
          eventResourceIdAccessor: 'projectId',
          eventColorAccessor: 'color',
          eventNameAccessor: 'name',
          editResourceConfig: hasPermission(user, FULL_ACCESS_PERMISSION)
            ? projectEditInFlowConfig
            : undefined,
          copyEvent: (event: ProjectScheduleEvent) => ({
            start: event.start,
            end: event.end,
            color: event.color,
            name: event.name,
            projectId: event.project.id,
            nameTemplate: event.nameTemplate,
            roles: event.roles,
            teamMemberRoles: event.teamMemberRoles,
          }),
          editPermission: PROJECT_SCHEDULE_PERMISSION,
          eventInfoComponent: EventInfoBox,
        }}
        bottomCalendar={
          leaveIds
            ? {
                calendarId: 'flow-holidays-calendar',
                eventIncludeRelations: [{ relation: 'project' }],
                eventAPIResource: ResourceScheduleProjectEventResource,
                eventAdditionalFilters,
                resourceAPIResource: TeamMemberResource,
                resourceAPIMethod: 'listForSchedules',
                resourceAdditionalFilters: { archived: false },
                resourceNameAccessor: (teamMember: TeamMember) => {
                  return `${teamMember.name}`;
                },
                eventResourceIdAccessor: 'teamMemberId',
                eventColorAccessor: ['project', 'eventColor'],
                eventNameAccessor: (event: ResourceScheduleEvent) =>
                  getProjectFullName(event.project),
                readOnly: true,
                filterEmptyRows: true,
                copyEvent: (event: ResourceScheduleEvent) => ({
                  start: event.start,
                  end: event.end,
                  color: event.project.eventColor,
                  teamMemberId: event.teamMemberId,
                  projectId: event.project.id,
                }),
              }
            : undefined
        }
      />
    </>
  );
};

export default ProjectSchedule;
