import React from 'react';
import styled from 'styled-components';
import { MonthlySalariesFindByDateResponse } from '../types';
import Decimal from 'decimal.js-light';
import { TeamMember } from '../../team/types';

const Label = styled.div`
  height: 75px;
  width: 280px;
  color: #fff;
  background-color: #00a650 !important;
  border-radius: 20px !important;
  box-shadow: none;
  font-family: MarkOT-Medium, sans-serif;
  font-size: 1.1rem;
  position: relative;
`;

const InnerContainer = styled.div`
  border-radius: 20px !important;
  background-color: #0084ff;
  height: 100%;
  clip-path: polygon(0 0, 0 115%, 100% -25%, 100% 0);
  font-family: MarkOT-Medium, sans-serif;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`;

const SalarySum = styled.div`
  text-align: right;
  position: absolute;
  top: 45px;
  width: 280px;
  font-size: 1.8rem;
  padding-right: 5px;
  font-weight: bold;
`;

const formatterFunction = new Intl.NumberFormat('bg', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format;

const TotalCashLabel = ({
  monthlySalaries,
  teamMembers,
  showPreviewValues,
}: {
  monthlySalaries: MonthlySalariesFindByDateResponse;
  teamMembers: TeamMember[];
  showPreviewValues: boolean;
}) => {
  const totalCash = monthlySalaries?.monthlySalaries?.teamMemberMonthlySalaries?.reduce(
    (acc, teamMemberSalary) => {
      if (
        teamMembers.find(
          (teamMember) => teamMember.id === teamMemberSalary.teamMemberId
        ) &&
        new Decimal(
          (showPreviewValues
            ? teamMemberSalary.previewPerformanceComponentTotal
            : teamMemberSalary.total) ?? 0
        )
          .sub(new Decimal(teamMemberSalary.bankCardPayment ?? 0))
          .isPositive()
      ) {
        return acc.add(
          new Decimal(
            (showPreviewValues
              ? teamMemberSalary.previewPerformanceComponentTotal
              : teamMemberSalary.total) ?? 0
          ).sub(new Decimal(teamMemberSalary.bankCardPayment ?? 0))
        );
      }
      return acc;
    },
    new Decimal(0)
  );

  return (
    <Label>
      <InnerContainer>
        <div>total cash</div>
      </InnerContainer>
      <SalarySum>{formatterFunction(Number(totalCash))}</SalarySum>
    </Label>
  );
};

export default TotalCashLabel;
