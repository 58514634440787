import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { FormFieldsComponentTypeProps } from '../../../components/forms/types';
import { ITEM_VALUE, NEGATIVE_VALUE, PROJECT_ITEM_TYPES } from '../constants';
import {
  SelectInputField,
  NumberInputField,
} from '../../../components/forms/fields';
import { SelectOptions } from '../../../types/forms';
import useAPIRequest from '../../../hooks/useAPIRequest';
import { ProjectSetting } from '../../settings/types';
import ProjectSettingResource from '../../../api/resources/ProjectSettingResource';
import {
  PROJECT_ITEM_TYPES_IMAGES,
  PROJECT_ITEM_TYPES_OTHER,
  PROJECT_ITEM_TYPES_VIDEOS,
  PROJECT_NEGATIVES_TYPES,
} from '../../settings/keys';

type Props = FormFieldsComponentTypeProps;

export const getProjectSettingsItemsKey = (value: number) => {
  switch (value) {
    case ITEM_VALUE:
      return [
        PROJECT_ITEM_TYPES_IMAGES,
        PROJECT_ITEM_TYPES_VIDEOS,
        PROJECT_ITEM_TYPES_OTHER,
      ];
    case NEGATIVE_VALUE:
      return [PROJECT_NEGATIVES_TYPES];
    default:
      return [];
  }
};

const ProjectItemFormFields = (props: Props) => {
  const { instance, ...formMethods } = props;

  const [itemOptions, setItemOptions] = useState<SelectOptions>([]);
  const {
    loading,
    data: response,
    performRequest: getItemTypes,
  } = useAPIRequest(ProjectSettingResource.list);

  const typeValue = formMethods.watch('type');

  useEffect(() => {
    if (typeValue) {
      const key = getProjectSettingsItemsKey(typeValue);
      getItemTypes({
        filter: { where: { or: key.map((k) => ({ key: k })) } },
      });
    }
  }, [getItemTypes, typeValue]);

  useEffect(() => {
    if (response) {
      const options: {
        key: string;
        value: string;
        text: string;
      }[] = [];
      response.data.forEach((setting: ProjectSetting) => {
        setting.value.forEach((v: { en: string; bg: string; id: string }) => {
          options.push({ key: v.id, text: v.en, value: v.en });
        });
      });
      const sortedOptions = options
        .slice()
        .sort((a, b) => a.text.localeCompare(b.text));
      setItemOptions(sortedOptions);
    }
  }, [response]);

  return (
    <>
      <Form.Group>
        <SelectInputField
          options={PROJECT_ITEM_TYPES}
          rules={{
            required: { value: true, message: 'Please select a type.' },
          }}
          name="type"
          label="Type"
          placeholder="Type"
          defaultValue={ITEM_VALUE}
          width="6"
          {...formMethods}
        />
        <SelectInputField
          label="Name"
          name="name"
          options={itemOptions}
          loading={loading}
          rules={{
            required: { value: true, message: 'Please specify a name.' },
          }}
          width="10"
          {...formMethods}
        />
      </Form.Group>
      <Form.Group>
        <NumberInputField
          label="Items"
          name="items"
          placeholder="0"
          rules={{
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          width="6"
          {...formMethods}
        />
        <NumberInputField
          label="Cost per Item"
          name="costPerItem"
          placeholder="0.00"
          rules={{
            min: { value: 0, message: 'Negative numbers are not allowed' },
          }}
          width="10"
          {...formMethods}
        />
      </Form.Group>
    </>
  );
};

export default ProjectItemFormFields;
